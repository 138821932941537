import React, { useEffect, useRef } from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import Navbar from 'components/Navbars/ITCreativeNav';
import Team from 'components/App/Team';
import Footer from 'components/Saas/Footer';
import SideMenu from 'components/ITCreative/SideMenu';



const PageLeadership = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  return (
    <MainLayout>
      {/* <TopNav style="1" /> */}
      <Navbar navbarRef={navbarRef} />
      <SideMenu />
      <main className="about-page style-5"> 
      <header className="style-3 overflow-hidden" data-scroll-index="0">
      <div className="container">
        <div className="content section-padding">
          <div className="row">
            <div className="col-lg-7 style-6">
              <div className="info" style={{ background: '#00000000'  }}>
                <h1 className="h1">Our  <span>Leaders</span></h1>
                <p className="p">We are driven by our core values and seek to create a friendly and
              welcoming environment that nurtures team spirit and excellence in
              every individual. We are constantly evolving and require people
              who are ready and willing to embrace our culture of excellent
              service delivery and prompt provision of financial services to our
              customers.</p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img ">   
        
        <img src="/assets/img/about/style_3_1.png" alt="" />   
      </div>
    </header>     
        <Team />
        
      </main>
      <Footer noWave />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title> Our Leadership Team - Mutual Trust MfBank Limited</title>
<meta name="description" content=" Meet the visionary leaders who are shaping the future of banking. Our team is committed to delivering excellence and customer satisfaction.
" />
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
      
    </>
  )
}

export default PageLeadership ;